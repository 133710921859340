Vue.config.silent = false;

window.scheman = new Vue({
	el: '#app',

	data: {
		query: {},

		loadingData: false,

		schedule: [],

		time: moment(),
	},

	mounted: function () {
		this.initQuery();
		this.initSearch(this.initSelections);
		this.initEvents();
		this.initSchedule();
		this.initTime();
	},

	computed: {
		currentDay: function () {
			return parseInt(this.time.format('d'));
		},

		currentHour: function () {
			return parseInt(this.time.format('H'));
		},

		currentSchedule: function () {
			return this.schedule.filter((item) => {
				return item.hour == this.currentHour && item.day == this.currentDay;
			});
		},
	},

	methods: {
		initQuery: function () {
			var query = {};
			var match;
			var pl     = /\+/g;
			var search = /([^&=]+)=?([^&]*)/g;
			var decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); };
			var q  = window.location.search.substring(1);

			while (match = search.exec(q)) {
				query[decode(match[1])] = decode(match[2]);
			}

			this.query = query;
		},

		initSearch: function (callback) {
			var $mainsearch = $('#main-search');

			$.ajax({
				url: '/api/all',
				type: 'get',
				success: (data) => {
					$mainsearch.removeClass('loading');

					$mainsearch.select2({
						data: data,
						placeholder: 'Busca maestros, salones, materias, NRC o salones libres...',
						allowClear: true,
					});

					if (typeof callback == 'function') {
						callback($mainsearch);
					}
				},
				error: function (xhr) {
					$mainsearch.removeClass('loading').addClass('error');

					$.notify('Algo pasó mientras intentaba cargar los datos ):');
				},
			});
		},

		initEvents: function () {
			var $mainsearch = $('#main-search');

			$mainsearch.on('select2:select', this.handleSearchChange);
			$mainsearch.on('select2:unselect', this.handleSearchChange);

			// enable window history
			window.onpopstate = this.onPopState;
		},

		initSelections: function ($mainsearch) {
			if ('q' in this.query) {
				$mainsearch.val(this.query.q.split(',')).trigger('change');
			}
		},

		initSchedule: function () {
			var objects = [];

			if ('q' in this.query && this.query.q) {
				objects = this.query.q.split(',');
			}

			objects.forEach(this.loadObject);
		},

		initTime: function() {
			moment.locale('es');

			setInterval(() => {
				this.time = moment();
			}, 1000);
		},

		onPopState: function (event) {
			this.schedule = [];

			this.initQuery();
			this.initSelections($('#main-search'));
			this.initSchedule();
		},

		loadObject: function (object) {
			var data   = {embed: 'subject.tutor,classroom'};
			var url    = '/api/schedule';
			var is_s   = true;
			var pieces = object.split('/');

			if (pieces[0] == 'freerooms') {
				url  = '/api/freeroom';
				is_s = false;
			} else if (pieces[0] == 'tutor') {
				data[`subject.${pieces[0]}_id`] = pieces[1];
			} else {
				data[pieces[0]+'_id'] = pieces[1];
			}

			this.loadingData = true;

			$.ajax({
				url     : url,
				type    : 'get',
				data    : data,
				success : (data) => {
					if (is_s) {
						data.forEach((item) => {
							this.schedule.push(item);
						});
					} else {
						data.forEach((item) => {
							item.subject = {
								name: 'Salón libre',
								nrc: '',
								color: '#8E8E8E',
								tutor: {
									name: '',
								},
							};
							item.freeroom = true;

							this.schedule.push(item);
						});
					}
					this.loadingData = false;
				},
				error: function (xhr) {
					this.loadingData = false;
				},
			});
		},

		removeObject: function (object) {
			var pieces = object.split('/');
			var filterFunction = x => x;

			if (pieces[0] == 'tutor') {
				filterFunction = (item) => item.subject.tutor.id != pieces[1];
			} else if (pieces[0] == 'classroom') {
				filterFunction = (item) => item.classroom.id != pieces[1];
			} else if (pieces[0] == 'subject') {
				filterFunction = (item) => item.subject.id != pieces[1];
			} else if (pieces[0] == 'freerooms') {
				filterFunction = (item) => !item.freeroom;
			}

			this.schedule = this.schedule.filter(filterFunction);
		},

		handleSearchChange: function (event) {
			var $mainsearch = $(event.currentTarget);
			var selections  = $mainsearch.val();
			var title       = event.params.data.text;
			var url         = '/schedule?q=' + selections.join(',');

			if (event.type == 'select2:select') {
				this.loadObject(event.params.data.id);
			} else if (event.type == 'select2:unselect') {
				this.removeObject(event.params.data.id);
			}

			if (selections.length == 0) {
				setTimeout(() => $mainsearch.select2('close'));
			}

			window.history.pushState({
				selections: selections,
			}, title, url);
		},
	},
});
